import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getBlogPost } from "../api/actions";

import "../styles/blog.editor.scss";
import Blogposts from "../components/blogposts";

export default function BlogPost() {
	const [blogPost, setBlogPost] = useState(null);

	const { id } = useParams();

	const fetchData = async () => {
		let data = await getBlogPost(id);
		setBlogPost(data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (!blogPost)
		return (
			<div className="container blogpost">
				<h1>...</h1>
			</div>
		);

	return (
		<>
			<Helmet>
				<title>{blogPost.title} | Kodex Blog</title>
				<meta name="description" content={blogPost.description} />
				<meta name="author" content={`${blogPost.userPosted.firstName} ${blogPost.userPosted.lastName} | Kodex`} />
				<meta name="keywords" content={blogPost.tags.map((t) => t.name).join(",")} />
				<meta property="og:url" content={`https://kodex.si/blog/${blogPost.postId}`} />
				<meta property="og:type" content="article" />
				<meta property="og:title" content={blogPost.title} />
				<meta property="og:description" content={blogPost.description} />
				<meta
					property="og:image"
					content={
						blogPost.headerImage
							? blogPost.headerImage
							: "https://scontent-vie1-1.xx.fbcdn.net/v/t39.30808-6/279033888_505982287905746_265423122967790166_n.png?_nc_cat=100&ccb=1-7&_nc_sid=e3f864&_nc_ohc=qbUSqFFjlVsAX-AWeLN&_nc_ht=scontent-vie1-1.xx&oh=00_AT9sxXRdbJAgoilSt0WaG97b6_6dZ6Tx_OwANQUrpE602Q&oe=62D0DAF3"
					}
				/>
			</Helmet>
			<div className="container blogpost">
				<div style={{ marginTop: "10vh", marginBottom: 0 }}>
					<a href="../blog">{"<- Blog"}</a>
				</div>
				<h2 className="title" style={{ marginTop: 30 }}>
					{blogPost.title}
				</h2>
				<h3 className="desc">{blogPost.description}</h3>
				<span className="user pre-wrap">
					By{" "}
					<b>
						{blogPost.userPosted.firstName} {blogPost.userPosted.lastName}
					</b>{" "}
					in <b>{blogPost.tags.map((t) => t.name).join(",")}</b>
					{"\r\n"}
					{new Date(blogPost.dateCreated).toLocaleDateString("sl-SI")}
				</span>
				<br /> <br /> <br />
				<div dangerouslySetInnerHTML={{ __html: blogPost.content }}></div>
			</div>
		</>
	);
}

