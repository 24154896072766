import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export default function Project({
	image_arr,
	images,
	name,
	technologies,
	tech_arr,
	tagline,
	description,
	direction,
	link,
	style,
}) {
	return (
		<div style={{ ...style, position: "relative", height: "fit-content" }}>
			<div className="work-boxer">
				<Marquee direction={direction} gradient={false}>
					{images.map((im) => (
						<img key={im} src={image_arr[im]} alt={im} />
					))}
				</Marquee>
			</div>
			<div className="work-boxer-desc">
				<div>
					{link && (
						<Link to={link}>
							<h2 className="pre-wrap" style={{ margin: 0 }}>
								{name}
							</h2>
						</Link>
					)}
					{!link && (
						<h2 className="pre-wrap" style={{ margin: 0 }}>
							{name}
						</h2>
					)}
					<div style={{ display: "flex", alignItems: "center", marginTop: 35 }}>
						{technologies.map((tech) => (
							<img src={tech_arr[`${tech}.png`]} className="project-tech" alt={tech} />
						))}
					</div>
				</div>
				<div className="desc-text pre-wrap">
					<h3 style={{ marginBottom: 0 }}>{tagline}</h3>
					<h5 style={{ marginTop: 5 }}>{description}</h5>
					{link && (
						<Link
							to={link}
							style={{
								paddingLeft: 28,
								paddingRight: 28,
								paddingTop: 14,
								paddingBottom: 14,
								color: "var(--primary-color)",
								backgroundColor: "var(--secondary-color)",
								borderRadius: 8,
								fontWeight: "bold",
								fontSize: 16,
							}}
						>
							Read More
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

