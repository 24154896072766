import axios from "axios";

export async function getBlogPosts() {
	let data = await axios.get(`${process.env.REACT_APP_API_URL}/posts`);
	return data.data;
}

export async function getBlogPostsByTags(tags) {
	let data = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, { data: { tags: tags } });
	return data.data;
}

export async function getBlogPost(id) {
	let data = await axios.get(`${process.env.REACT_APP_API_URL}/post/${id}`);
	return data.data;
}

export async function createPost(postData) {
	let data = await axios.post(`${process.env.REACT_APP_API_URL}/post`, postData);
	return data;
}

export async function deleteBlogPost(id) {
	let data = await axios.delete(`${process.env.REACT_APP_API_URL}/post/${id}`);
	return data.data;
}

