export default function TeamMember({ name, image, image_gradient, role }) {
	return (
		<div className="team-member">
			<img src={image} alt="team-member" className="normal-image" />
			<img src={image_gradient} alt="team-member-gradient" className="gradient-image" />
			<div className="member-info">
				<h3 className="member-name pre-wrap">{name}</h3>
				<h3 className="member-role pre-wrap">{role}</h3>
			</div>
		</div>
	);
}

