import { useEffect, useState } from "react";
import { getBlogPosts } from "../api/actions";

import BlogPost from "./blogpost";

export default function Blogposts() {
	const [posts, setPosts] = useState(null);

	const fetchData = async () => {
		let data = await getBlogPosts();
		if (typeof data === "object") setPosts(data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				marginTop: 14,
				marginBottom: 14,
				alignItems: "stretch",
			}}
		>
			{posts === null && Array(3).map((a) => <div style={{ height: 230, width: "100%" }}></div>)}
			{posts && posts.slice(0, 3).map((post) => <BlogPost post={post} />)}
		</div>
	);
}

