import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getBlogPosts } from "../api/actions";
import BlogPost from "../components/blogpost";
import Cursor from "../components/cursor";

export default function Blog() {
	const [posts, setPosts] = useState([]);

	const fetchData = async () => {
		console.log("fetching");
		let data = await getBlogPosts();
		console.log(data);
		setPosts(data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="container">
				<div style={{ marginTop: "20vh" }}>
					<a href="../">{"<- Main Page"}</a>
				</div>
				<h1 style={{ marginTop: 10, pointerEvents: "none", marginBottom: 48 }}>BLOG</h1>
				<div className="blogposts-container">
					{posts.map((post) => (
						<BlogPost post={post} />
					))}
				</div>
			</div>
			<Cursor />
		</>
	);
}

