export default function BlogPost({ post }) {
	return (
		<a href={`${window.location.origin}/blog/${post.postId}`}>
			<div className="blogpost-item">
				<h2>{post.title}</h2>
				<h3>{post.description}</h3>
				<p>
					Posted by{" "}
					<b>
						{post.userPosted.firstName} {post.userPosted.lastName}
					</b>{" "}
					in <b>{post.tags.map((t) => t.name).join(",")}</b> on{" "}
					<b>{new Date(post.dateCreated).toLocaleDateString("sl-SI")}</b>
				</p>
			</div>
		</a>
	);
}

