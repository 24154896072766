import React, { useEffect, useState } from "react";

import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useAuth0 } from "@auth0/auth0-react";

import draftToHtml from "draftjs-to-html";

import { createPost } from "../api/actions";

export default function WriteBlogpost() {
	const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const [blogTitle, setBlogTitle] = useState("");
	const [blogDescription, setBlogDescription] = useState("");
	const [blogTags, setBlogTags] = useState("");

	useEffect(() => {
		if (!isAuthenticated && !isLoading) loginWithRedirect({ redirectUri: `${window.location.origin}/blog/write` });
	}, [isLoading]);

	const onSave = async () => {
		if (blogTitle.length > 0 && blogDescription.length > 0) {
			let data = {
				title: blogTitle,
				description: blogDescription,
				tags: blogTags.split(",").map((tag) => ({ name: tag })),
				content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
			};
			let resp = await createPost(data);
			if (resp.status === 200) {
				window.location.href = window.location.origin + "/blog";
			} else {
				alert(`${resp.status}: ${resp.statusText} ${resp.data}`);
			}
		}
	};

	return (
		<>
			<div style={{ width: "100%", marginTop: 100, position: "relative" }} className="container blog-write blogpost">
				<h3>Create Blogpost</h3>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<h3>Title</h3>
					<input placeholder="Title" onChange={(e) => setBlogTitle(e.target.value)} />
					<h3>Description</h3>
					<input placeholder="Description" onChange={(e) => setBlogDescription(e.target.value)} />
					<h3>Tags</h3>
					<input placeholder="TypeScript,Node" onChange={(e) => setBlogTags(e.target.value)} />
				</div>
				<h3>Content</h3>
				<Editor
					toolbarClassName="editor"
					editorClassName="blog-content"
					editorState={editorState}
					onEditorStateChange={(editorState) => setEditorState(editorState)}
					editorStyle={{ padding: 24 }}
					toolbarStyle={{ position: "fixed", top: 100, zIndex: 99 }}
				/>
				<button className="primary-button" onClick={onSave}>
					Save
				</button>
			</div>
		</>
	);
}

