import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Cursor from "../../components/cursor";

import sts1 from "../../assets/work-images/STS-1.jpg";
import sts2 from "../../assets/work-images/STS-2.jpg";
import sts3 from "../../assets/work-images/STS-3.jpg";
import sts4 from "../../assets/work-images/STS-4.jpg";
import sts5 from "../../assets/work-images/STS-5.jpg";
import sts6 from "../../assets/work-images/STS-6.jpg";
import sts7 from "../../assets/work-images/STS-7.jpg";
import sts8 from "../../assets/work-images/STS-8.jpg";
import sts9 from "../../assets/work-images/STS-9.jpg";
import sts10 from "../../assets/work-images/STS-10.jpg";

export default function STSPage() {
	return (
		<>
			<div id="container" className="container">
				<div style={{ marginTop: "20vh" }}>
					<a href="../">{"<- Main Page"}</a>
				</div>
				<h2 style={{ marginTop: 10, pointerEvents: "none", marginBottom: 56 }}>Scan The Space</h2>
				<h3 style={{ fontSize: 36 }}>Intelligent Parking System</h3>
				<p className="pre-wrap" style={{ marginBottom: 36, fontSize: 20 }}>
					{`We at KODEX PROGRAMIRANJE d.o.o. are more than aware of the fact that in order for a company to sustain the lead in the market, it must constantly focus on research and development. This is the only true way to foster innovation within a organisation. Our challenge in the field of research is represented by the project »Multimodal urban mobility« for which we and our consortium partners at msg life odateam d.o.o. and infinCUBE d.o.o. were granted funding from the Republic of Slovenia and the European union, the European regional development fund as part of the Public tender for “Incentives for research and development projects 2«.

Smart management of mobility within cities is one of the key elements of smart cities. With the development of IoT and IoS this field also witnesed intense development. However the already developed solutions do not fit the needs of all cities. The result of the project »Multimodal urban mobility« will be the development of a smart platform for parking management and the fostering of multimodal ways of traveling to final destinations in urban settings with the following goals:
- reduction of traffic and environmental pollution in urban centers,
- simplification and facilitation of parking in city centers, and
- simplification and promotion of a multimodal, sustainable way of traveling.

Selected members of all consortium partners have excitedly devoted themselves to the research and development of this innovative solution, which will last from 9.7.2020 until 8.7.2022.

For more information on the European cohesion policy in Slovenia, please go to www.eu-skladi.si`}
				</p>
				<h3 style={{ fontSize: 36 }}>Mobile App</h3>
				<div
					style={{
						display: "grid",
						width: "100%",
						gap: 10,
						gridTemplateColumns: "1fr 1fr 1fr",
						gridAutoFlow: "row dense",
						height: "fit-content",
						maxWidth: "100%",
					}}
				>
					<div>
						<img style={{ objectFit: "contain", gridColumn: 1, width: "100%" }} src={sts3} alt="sts-3" />
					</div>
					<div>
						<img style={{ objectFit: "contain", gridColumn: 1, width: "100%" }} src={sts4} alt="sts-4" />
					</div>
					<div>
						<img style={{ objectFit: "contain", gridColumn: 1, width: "100%" }} src={sts5} alt="sts-5" />
					</div>
					<div style={{ gridColumn: "1/4" }}>
						<img style={{ objectFit: "contain", width: "100%" }} src={sts6} alt="sts-6" />
					</div>
				</div>
				<h3 style={{ fontSize: 36 }}>Administration Panel</h3>
				<div
					style={{
						display: "grid",
						width: "100%",
						gap: 10,
						gridTemplateColumns: "1fr 1fr 1fr",
						gridAutoFlow: "row dense",
						height: "fit-content",
						maxWidth: "100%",
					}}
				>
					<div>
						<img style={{ objectFit: "contain", gridColumn: 1, width: "100%" }} src={sts1} alt="sts-1" />
					</div>
					<div>
						<img style={{ objectFit: "contain", gridColumn: 1, width: "100%" }} src={sts2} alt="sts-2" />
					</div>
				</div>
				<h3 style={{ fontSize: 36 }}>Scanner View</h3>
				<div
					style={{
						display: "grid",
						width: "100%",
						gap: 10,
						gridTemplateColumns: "1fr 1fr 1fr",
						gridAutoFlow: "row dense",
						height: "fit-content",
						maxWidth: "100%",
					}}
				>
					<div style={{ gridColumn: "1/3" }}>
						<img style={{ objectFit: "contain", width: "100%" }} src={sts7} alt="sts-7" />
					</div>
					<div>
						<img style={{ objectFit: "contain", width: "100%" }} src={sts8} alt="sts-8" />
					</div>
					<div>
						<img style={{ objectFit: "contain", width: "100%" }} src={sts9} alt="sts-9" />
					</div>
					<div>
						<img style={{ objectFit: "contain", width: "100%" }} src={sts10} alt="sts-10" />
					</div>
				</div>
				<p className="pre-wrap" style={{ marginTop: 36, fontSize: 56, fontWeight: "bold", lineHeight: 1.2 }}>
					{`For cooperative inquiry, and partnership and platform presentation please contact us at `}
					<a style={{ color: "var(--secondary-color)" }} href="mailto:vasil.josifovski@kodex.si">
						vasil.josifovski@kodex.si
					</a>
				</p>
			</div>

			<Cursor />
		</>
	);
}

