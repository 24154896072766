import React from "react";

import Marquee from "react-fast-marquee";

import TeamMember from "../components/team.member";
import Project from "../components/project";
import Initializer from "../components/initializer";
import Cursor from "../components/cursor";

import kodexOutlined from "../assets/kodex-logo-outline.png";
import Blogposts from "../components/blogposts";

export default function Home() {
	function importAll(r) {
		let images = {};
		r.keys().forEach((item, index) => {
			images[item.replace("./", "")] = r(item);
		});
		return images;
	}

	const images = importAll(require.context("../assets/work-images", false, /\.(png|jpg|svg|jpeg)$/));
	const team_images = importAll(require.context("../assets/team-images", false, /\.(png|jpg|svg|jpeg)$/));
	const tech_images = importAll(require.context("../assets/tech-logos", false, /\.(png|jpg|svg|jpeg)$/));
	const partner_logos = importAll(require.context("../assets/partner-logos", false, /\.(png|jpg|svg|jpeg)$/));

	return (
		<>
			<div id="container" className="container">
				<h2 className="pre-wrap title">{"NEXT\nLEVEL"}</h2>
				<h1 className="secondary subtitle">SOLUTIONS</h1>
				<div className="parallax-bg">
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
					<img className="parallax" src={kodexOutlined} alt="kodex-outlined-logo" />
				</div>
				<a
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
					}}
				></a>
				<h5 className="scroll-down">SCROLL DOWN</h5>
				<div style={{ marginTop: 200, marginBottom: 100 }}>
					<h2 className="kodex" style={{ textAlign: "center" }}>
						kodex<span className="outline">?</span>
					</h2>
				</div>
				<div style={{ marginTop: 100, marginBottom: 100 }}>
					<h5 style={{ marginLeft: 200 }} className="kodex-sub">
						WE ARE
					</h5>
					<h2 className="kodex" style={{ textAlign: "center", marginTop: -30 }}>
						<span className="outline">kod</span>experts
					</h2>
				</div>
				<div style={{ marginTop: 100, marginBottom: 100 }}>
					<h5 style={{ marginLeft: 200 }} className="kodex-sub">
						WE FOLLOW
					</h5>
					<h2 className="kodex" style={{ textAlign: "center", marginTop: -30 }}>
						<span className="outline">your</span>kodex
					</h2>
				</div>
				<div style={{ marginTop: 100, marginBottom: 100 }}>
					<h5 style={{ marginLeft: 200 }} className="kodex-sub">
						INTEGRATED IN YOUR BUSINESS'
					</h5>
					<h2 className="kodex" style={{ textAlign: "center", marginTop: -30 }}>
						<span className="outline">dna</span>kode
						<span className="outline">x</span>
					</h2>
				</div>

				<div style={{ marginTop: 100 }}>
					<h1 className="pre-wrap" style={{ marginBottom: 24 }}>
						{"SERVICES"}
					</h1>
				</div>
				<div className="services">
					<h2 className="outline service-item">Web Development</h2>
					<h2 className="outline service-item">Web Design</h2>
					<h2 className="outline service-item">Application Development</h2>
					<h2 className="outline service-item">Progressive Web Apps</h2>
					<h2 className="outline service-item">Branding</h2>
				</div>
				<div style={{ background: "#060a48", marginTop: -100 }}>
					<h1 className="pre-wrap">{"CLIENTS&\nPARTNERS"}</h1>
				</div>
				<Marquee speed={50} gradient={false} className="partner-logo-container">
					<img className="partner-logo" src={partner_logos["bzs.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["cryptofinance.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["dcc-hail.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["delta.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["farmakem.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["imagine.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["minute4health.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["msglife.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["petrushev-capital.png"]} alt="bzs" />
					<img className="partner-logo" src={partner_logos["uprastan.png"]} alt="bzs" />
				</Marquee>
				<div style={{ marginTop: -100 }}>
					<h1 className="pre-wrap">{"OUR\nWORK"}</h1>
				</div>
				<Project
					style={{ marginTop: -70 }}
					image_arr={images}
					images={["STS-1.jpg", "STS-2.jpg", "STS-3.jpg", "STS-4.jpg", "STS-5.jpg", "STS-6.jpg"]}
					name={"Scan\nThe\nSpace"}
					tech_arr={tech_images}
					technologies={["java", "react", "react-native", "typescript", "postgre"]}
					direction={"left"}
					tagline={"Intelligent Parking System"}
					description={"Automated management processes &\nintuitive user experience"}
					link={"./projects/sts"}
				/>
				<Project
					image_arr={images}
					images={["pcap-1.jpg", "pcap-2.jpg", "pcap-4.jpg", "pcap-5.jpg", "pcap-6.jpg"]}
					name={"Delta OTC"}
					tech_arr={tech_images}
					technologies={["node", "postgre", "react", "typescript"]}
					direction={"right"}
					tagline={"Over-The-Counter Crypto Trading Platform"}
				/>
				<Project
					image_arr={images}
					images={["BZS-1.jpg", "BZS-2.jpg", "BZS-3.jpg", "BZS-4.jpg"]}
					name={"Boksarska\nZveza\nSlovenije"}
					tech_arr={tech_images}
					technologies={["node", "postgre", "react", "typescript"]}
					direction={"left"}
					tagline={"Boxing Federation Platform"}
					description={"Competition management, organization\nand automated fixture draw system"}
				/>
				<Project
					image_arr={images}
					images={["cf-1.jpg", "cf-2.jpg", "cf-3.jpg", "cf-4.jpg", "cf-5.jpg", "cf-6.jpg", "cf-7.jpg"]}
					name={"Crypto\nFinance"}
					tech_arr={tech_images}
					technologies={["react", "typescript", "gatsby"]}
					direction={"right"}
					tagline={"Crypto Trader Entry Point"}
				/>
				<Project
					image_arr={images}
					images={["igas-1.jpg", "igas-2.jpg", "igas-3.jpg", "igas-4.jpg"]}
					name={"iGas"}
					tech_arr={tech_images}
					technologies={["react-native", "typescript", "php"]}
					direction={"left"}
					tagline={"Smart Gas Counter Tracking"}
				/>
				<Project
					image_arr={images}
					images={["M4h-1.jpg", "M4h-2.jpg", "M4h-3.jpg", "M4h-4.jpg"]}
					name={"Minute4\nHealth"}
					tech_arr={tech_images}
					technologies={["node", "react", "postgre"]}
					direction={"right"}
					tagline={"Ecommerce Platform"}
				/>
				<Project
					image_arr={images}
					images={["Upra-1.jpg", "Upra-2.jpg", "Upra-3.jpg", "Upra-4.jpg", "Upra-5.jpg"]}
					name={"Uprastan"}
					tech_arr={tech_images}
					technologies={["node", "java", "php", "react"]}
					direction={"left"}
					tagline={"Facility Cost Distribution"}
					description={"Automated facility billing system"}
				/>
				<Project
					image_arr={images}
					images={["STC-1.jpg", "STC-2.jpg", "STC-3.jpg", "STC-4.jpg", "STC-5.jpg"]}
					name={"Share\nThe\nCall"}
					tech_arr={tech_images}
					technologies={["node", "react-native", "react", "typescript"]}
					direction={"right"}
					tagline={"Basketball Referree Assistant"}
				/>
				<Project
					image_arr={images}
					images={["Motogram-1.jpg", "Motogram-2.jpg", "Motogram-3.jpg"]}
					name={"Motogram"}
					tech_arr={tech_images}
					technologies={["node", "react", "mysql"]}
					direction={"left"}
					tagline={"Vehicle Hail Damage Assessment System"}
				/>
				<div style={{ marginTop: 200 }}>
					<h1 style={{ zIndex: -2, position: "relative" }} className="pre-wrap">
						{"CORE\nTEAM"}
					</h1>
				</div>
				<div className="team">
					<TeamMember
						image={team_images["Ace.png"]}
						image_gradient={team_images["Ace_Gradient.png"]}
						role={"Backend & DevOps Lead\nWeb Developer"}
						name={"Aleksandar\nBozhinovski"}
					/>
					<TeamMember
						image={team_images["Kire.png"]}
						image_gradient={team_images["Kire_Gradient.png"]}
						role={"Frontend Lead\nWeb Developer\nUI/UX Design"}
						name={"Kiril\nKrsteski"}
					/>
					<TeamMember
						image={team_images["Sase.png"]}
						image_gradient={team_images["Sase_Gradient.png"]}
						role={"Project Manager\nWeb Developer"}
						name={"Sasho\nStojchevski"}
					/>
					<TeamMember
						image={team_images["Vasil.png"]}
						image_gradient={team_images["Vasil_Gradient.png"]}
						role={"Chief Executive Officer"}
						name={"Vasil\nJosifovski"}
					/>
				</div>

				<div style={{}}>
					<h1 className="pre-wrap" style={{ marginBottom: 24 }}>
						{"BLOG"}
					</h1>
				</div>
				<Blogposts />
				<a href="./blog">VIEW ALL {"->"}</a>

				<div style={{ paddingTop: 100, paddingBottom: 100 }}>
					<p className="pre-wrap">
						{`We at KODEX PROGRAMIRANJE d.o.o. are more than aware of the fact that in order for a company to sustain the lead in the market, it must constantly focus on research and development. This is the only true way to foster innovation within a organisation. Our challenge in the field of research is represented by the project »Multimodal urban mobility« for which we and our consortium partners at msg life odateam d.o.o. and infinCUBE d.o.o. were granted funding from the Republic of Slovenia and the European union, the European regional development fund as part of the Public tender for “Incentives for research and development projects 2«.

Smart management of mobility within cities is one of the key elements of smart cities. With the development of IoT and IoS this field also witnesed intense development. However the already developed solutions do not fit the needs of all cities. The result of the project »Multimodal urban mobility« will be the development of a smart platform for parking management and the fostering of multimodal ways of traveling to final destinations in urban settings with the following goals:
- reduction of traffic and environmental pollution in urban centers,
- simplification and facilitation of parking in city centers, and
- simplification and promotion of a multimodal, sustainable way of traveling.

Selected members of all consortium partners have excitedly devoted themselves to the research and development of this innovative solution, which will last from 9.7.2020 until 8.7.2022.

For more information on the European cohesion policy in Slovenia, please go to www.eu-skladi.si`}
					</p>
					<div className="eu-logos">
						<img src={partner_logos["mgr.png"]} style={{ marginRight: 20 }} alt="mgr" />
						<img src={partner_logos["ekp.png"]} alt="ekp" />
					</div>
				</div>
				<div style={{ height: 0, width: "100%", marginBottom: 142 }} />
				<div className="contact">
					<h2 style={{ marginBottom: 0 }} className="primary contact-title">
						CONVINCED?
					</h2>
					<h2 style={{ marginBottom: 0 }} className="pre-wrap outline-secondary contact-subtitle">
						{"Contact us and get your\ndream project going."}
					</h2>
					<a href="mailto:inquiries@kodex.si">
						<h3 style={{ marginTop: 20 }} className="pre-wrap contact-mail">
							{"inquiries@kodex.si"}
						</h3>
					</a>
				</div>
			</div>
			<Initializer images={images} />
			<Cursor />
		</>
	);
}

