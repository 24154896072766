import { useEffect, useState } from "react";
import lax from "lax.js";

import kodexLogo from "../assets/kodex_blue.png";

export default function Initializer({ images }) {
	const [loading, setLoading] = useState(true);

	function getTranslateXY(element) {
		const style = window.getComputedStyle(element);
		const matrix = new DOMMatrixReadOnly(style.transform);
		return {
			translateX: matrix.m41,
			translateY: matrix.m42,
		};
	}

	useEffect(() => {
		setTimeout(() => {
			lax.init();

			// Setup mouse move listener
			document.addEventListener(
				"mousemove",
				function (e) {
					lax.__cursorX = e.clientX;
					lax.__cursorY = e.clientY;
				},
				false
			);

			// Add lax driver for cursorX
			lax.addDriver("cursorX", function () {
				return lax.__cursorX || 0;
			});

			// Add lax driver for cursorY
			lax.addDriver("cursorY", function () {
				return lax.__cursorY || 0;
			});

			lax.addDriver(
				"scrollY",
				function () {
					if (/Android|iOS|iPad|iPhone|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
						return window.scrollY;
					} else {
						return -getTranslateXY(document.getElementById("container")).translateY;
					}
				},
				{ inertiaEnabled: true }
			);

			lax.addElements(".parallax", {
				cursorX: {
					translateX: [
						[0, "screenWidth"],
						["10*(index)-10", "10*(index%3)+10"],
					],
				},
				cursorY: {
					translateY: [
						[0, "screenHeight"],
						["10*(index)-10", "10*(index%3)+10"],
					],
				},
			});

			lax.addElements(".kodex", {
				scrollY: {
					translateX: [
						["elInY", "elCenterY"],
						["-screenWidth/3", 0],
					],
					scale: [
						["elCenterY", "elOutY"],
						[1, 2],
					],
					opacity: [
						["elInY", "elCenterY", "elOutY"],
						[0, 1, 0],
					],
				},
			});

			lax.addElements(".kodex-sub", {
				scrollY: {
					translateX: [
						["elInY", "elCenterY", "elOutY"],
						["-screenWidth/2", 0, "screenWidth/2"],
					],
					opacity: [
						["elInY", "elCenterY", "elOutY"],
						[0, 1, 0],
					],
				},
			});

			lax.addElements(".work-boxer", {
				scrollY: {
					opacity: [
						["elInY", "elCenterY", "elOutY"],
						[0.5, 1, 0.5],
					],
				},
			});
			lax.addElements(".work-boxer-desc", {
				scrollY: {
					translateX: [
						["elInY", "elCenterY-200", "elOutY"],

						{
							768: ["-screenWidth/8", 0, 20],
							1400: ["-screenWidth/4", 0, 200],
						},
					],
				},
			});
			lax.addElements(".service-item", {
				scrollY: {
					rotate: [
						["elInY", "elCenterY", "elOutY"],
						[40, 0, -40],
					],
					opacity: [
						["elInY", "elCenterY", "elOutY"],
						[0, 1, 0],
					],
				},
			});
			setTimeout(() => {
				setLoading(false);
			}, 100);
		}, 2000);
	}, []);

	return (
		<div className={`initializer ${!loading && "done"}`}>
			<div className="images">
				{Object.keys(images).map((key) => (
					<link key={key} rel="preload" as="image" href={images[key]}></link>
				))}
			</div>
			<div className={`loader`}>
				<img src={kodexLogo} alt="kodex-logo" />
			</div>
		</div>
	);
}

