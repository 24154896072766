import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export default function TokenService() {
	const { getAccessTokenSilently } = useAuth0();
	axios.interceptors.request.use(
		async function (config) {
			try {
				const token = await getAccessTokenSilently();
				config.headers = { ...config.headers, Authorization: "Bearer " + token };
			} catch (e) {}
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
	return <></>;
}

