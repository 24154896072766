import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";

import App from "./App";
import Home from "./pages/home";

import reportWebVitals from "./reportWebVitals";

import Blog from "./pages/blog";
import WriteBlogpost from "./pages/blog.write";
import BlogPost from "./pages/blogpost";
import STSPage from "./pages/projects/sts";

const rootElement = document.getElementById("root");

render(
	<Auth0Provider
		domain="kodex-si.eu.auth0.com"
		clientId="FrrbPc71BYDGeUNl4PSRNpaUzaM5Cydo"
		audience="https://kodex.si/blog"
		redirectUri={window.location.origin}
	>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<Home />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/blog/write" element={<WriteBlogpost />} />
					<Route path="/blog/:id" element={<BlogPost />} />
					<Route path="/projects/sts" element={<STSPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</Auth0Provider>,
	rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

