import "./App.scss";

import React from "react";
import { Outlet } from "react-router-dom";

import blockLogo from "./assets/kodex-logo-block.png";
import TokenService from "./api/token.interceptor";

function App() {
	return (
		<>
			<div className="header">
				<img src={blockLogo} alt="block-logo" />
			</div>
			<Outlet />
			<TokenService />
		</>
	);
}

export default App;

